import { graphql } from "gatsby"
import React from "react"
import "lazysizes"
import ReactPlayer from 'react-player'

import "./index.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query featuredQuery {
    allDirectusFeaturedProject(sort: {fields: sort}) {
      nodes {
        project_image {
          localFile {
            childImageSharp {
              original {
                src
              }
              resize(height: 100) {
                src
              }
              resolutions(width: 640) {
                src
              }
            }
          }
        }
        id
        project_name
        project_video
      }
    }
  }
`;

const IndexPage = ({ data: { allDirectusFeaturedProject: projects } }) => (
  <Layout>
    <SEO title=" " />

    { projects.nodes.map((project) => (
      <div key={ project.id } className="project">

        { project.project_image.localFile &&
            <figure>
              <img
                  className="lazyload"
                  src={ project.project_image.localFile.childImageSharp.resize.src }
                  data-src={ project.project_image.localFile.childImageSharp.original.src }
                  data-srcset={ 
                     `${ project.project_image.localFile.childImageSharp.resolutions.src } 640w,
                      ${ project.project_image.localFile.childImageSharp.original.src } 1920w` }
                  alt={ project.project_name } />     
            </figure>
        }
        
        { project.project_video &&
          <ReactPlayer
            url={ `https://player.vimeo.com/video/${ project.project_video }` }
            playing muted loop className="lazyload" />
        }
      </div>

    ))}
  </Layout>
)


export default IndexPage
